<template>
  <div class="my-5 py-5">
    <!-- <main-jumbotron /> -->
    <b-container class="mt-5 exhibition py-5">
      <section v-if="photos.exhibition.length > 0">
        <article class="text-center">
          <h1 class="main-heading ">{{ $t("heading.honorary_exhibition") }}</h1>
          <p
            class="main-description mx-auto"
            v-html="GlobalSettings.honorary_exhibition"
          />
        </article>
        <b-row>
          <b-col
            v-for="(photo, i) in photos.exhibition"
            :key="i"
            cols="12"
            md="6"
          >
            <div class="img-wrap ratio-75">
              <div class="img-content">
                <b-img
                  class="exhibition__photo"
                  fluid-grow
                  :src="photo && photo.path"
                  :alt="photo && photo.description"
                />
              </div>
            </div>
          </b-col>
        </b-row>
        <b-button
          :loading="loading"
          v-if="meta.exhibition.current_page < meta.exhibition.last_page"
          class="button button--filled"
          @click="fetchItems(false, meta.exhibition.current_page + 1)"
          ><b-spinner class="mx-5" v-if="loading" variant="light" />
          <span v-else>{{ $t("button.load_more") }}</span></b-button
        >
      </section>

      <!-- INFORMATION CENTER -->

      <section v-if="photos.informationCenter.length > 0">
        <article class="text-center">
          <h1 class="main-heading ">{{ $t("heading.information_center") }}</h1>
          <p
            class="main-description mx-auto"
            v-html="GlobalSettings.information_center"
          />
        </article>

        <b-row>
          <b-col
            v-for="(photo, i) in photos.informationCenter"
            :key="i"
            cols="12"
            md="6"
          >
            <div class="img-wrap ratio-75">
              <div class="img-content">
                <b-img
                  class="exhibition__photo"
                  fluid-grow
                  :src="photo && photo.path"
                  :alt="photo && photo.description"
                />
              </div>
            </div>
          </b-col>
        </b-row>
        <b-button
          :loading="loading"
          v-if="
            meta.informationCenter.current_page <
              meta.informationCenter.last_page
          "
          class="button button--filled"
          @click="fetchItems(true, meta.informationCenter.current_page + 1)"
          ><b-spinner class="mx-5" v-if="loading" variant="light" />
          <span v-else>{{ $t("button.load_more") }}</span></b-button
        >
      </section>
    </b-container>
  </div>
</template>

<script>
import { IndexData } from "@/helpers/apiMethods";
import { mapGetters } from "vuex";

export default {
  name: "exhibition",

  data() {
    return {
      photos: {
        exhibition: [],
        informationCenter: []
      },
      meta: {
        exhibition: {},
        informationCenter: {}
      },
      loading: false
    };
  },

  computed: {
    ...mapGetters(["GlobalSettings"])
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      this.fetchItems();
      this.fetchItems(true);
    },

    fetchItems(informationCenter = false, page = 1) {
      this.loading = true;
      const query = { page };
      informationCenter && (query.information_center = true);
      IndexData({
        reqName: "photos-galleries",
        query
      })
        .then(res => {
          if (informationCenter) {
            this.photos.informationCenter.push(...res.data.photos_galleries);
            this.meta.informationCenter = res.data.meta;
          } else {
            this.photos.exhibition.push(...res.data.photos_galleries);
            this.meta.exhibition = res.data.meta;
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    }
  }
};
</script>

<style lang="scss" scoped>
.exhibition {
  section {
    text-align: center;
    .btn {
      margin: 4rem 0 6rem;
    }
  }
  &__photo {
    border-radius: 2rem;
    padding: 1rem;
  }
}
</style>
